import React, { Component } from "react";
import PageStepper from "../Custom/PageStepper";
import "react-circular-progressbar/dist/styles.css";
import EventDateSelectorSection from "./Sections/EventDateSelectorSection";
import EventSlotSelectorSection from "./Sections/EventSlotSelectorSection";
import EventDetailsSubmissionSection from "./Sections/EventDetailsSubmissionSection";
import { Segment } from "semantic-ui-react";
import axios from "axios";
import { DateTime as LuxonDateTime } from "luxon";

import {
  FETCH_EVENT_DETAILS,
  FETCH_FREE_BUSY_SLOTS_DETAILS,
  SUBMIT_EVENT_BOOKING,
} from "../../utils/Controller/URLConstants";

export default class EventScheduleComponent extends Component {
  state = {
    event: undefined,
    schedule: undefined,
    timeslots: undefined,
    currentPageIndex: 0,
    selectedDate: undefined,
    currentDaySlots: undefined,
    selectedSlot: undefined,
    additionalText: "",
    loading: false,
  };

  componentDidMount() {
    const { eventId, uid } = this.props;
    if (eventId) {
      this.fetchEventDetails(eventId, uid);
    }
  }

  fetchEventDetails = async (eventId, uid) => {
    let error = true;
    try {
      if (eventId) {
        const response = await axios.get(
          `${FETCH_EVENT_DETAILS}?id=${eventId}&token=${uid}&bp=1`
        );
        if (response.status === 200) {
          if (response.data["status"] === 0) {
            let eventDetails = response.data["data"];
            if (eventDetails) {
              error = false;
              this.setState({
                event: eventDetails.event,
                schedule: eventDetails.schedule,
                timeslots: eventDetails.timeslots,
              });
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    if (error) {
      const { onSubmitted } = this.props;
      onSubmitted();
    }
    return null;
  };

  getDateTimeSlotsForDay = (selectedDate) => {
    if (!selectedDate) return;
    const { schedule, event } = this.state;
    const availableSlots = Object.values(schedule.timeslots)
      .map((e) => Object.values(e))
      .flatMap((e) => e);

    let currentDaySlots = availableSlots.filter(
      (slot) => slot.date.day === selectedDate.getDay()
    );

    const dates = [];

    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    for (const slot of currentDaySlots) {
      const dateTime = selectedDate;

      const swedenTimeStartAt = LuxonDateTime.fromObject(
        {
          year: dateTime.getFullYear(),
          month: dateTime.getMonth() + 1,
          day: dateTime.getDate(),
          hour: slot.startTimeAt.hour,
          minute: slot.startTimeAt.minute,
        },
        { zone: schedule.timezone.id }
      );

      const startAt = swedenTimeStartAt.setZone(localTimezone);

      const swedenTimeEndAt = LuxonDateTime.fromObject(
        {
          year: dateTime.getFullYear(),
          month: dateTime.getMonth() + 1,
          day: dateTime.getDate(),
          hour: slot.endTimeAt.hour,
          minute: slot.endTimeAt.minute,
        },
        { zone: schedule.timezone.id }
      );

      const endAt = swedenTimeEndAt.setZone(localTimezone);

      const eventDurationInMinutes = event.details.duration;

      const durationInMinutes = (() => {
        if (eventDurationInMinutes < 30) {
          return eventDurationInMinutes + 10;
        }
        return eventDurationInMinutes;
      })();

      const slotCount = Math.floor(
        (endAt.toMillis() - startAt.toMillis()) / (durationInMinutes * 60000) // Difference in milliseconds converted to minutes
      );

      const newDates = Array.from({ length: slotCount }, (_, index) => {
        const newDate = new Date(startAt.toMillis());
        newDate.setMinutes(startAt.minute + durationInMinutes * index);
        return newDate;
      });

      dates.push(...newDates);
    }

    return dates.sort((a, b) => a.getTime() - b.getTime());
  };

  fetchFreeBusySlots = async (selectedDate) => {
    let timeslotsResponse = null;

    try {
      const { event } = this.state;
      const { uid } = this.props;
      const response = await axios.get(
        `${FETCH_FREE_BUSY_SLOTS_DETAILS}?eventId=${
          event.id
        }&token=${uid}&offset=${7200000}&date=${1}&month=${
          selectedDate.getMonth() + 1
        }&year=${selectedDate.getFullYear()}&bp=1`
      );
      if (response.status === 200) {
        if (response.data["status"] === 0) {
          let data = response.data["data"];
          if (data) {
            timeslotsResponse = data["timeslots"];
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.setState({
      timeslots: timeslotsResponse,
      loading: false,
    });
  };

  convertSelectedSlotToMeetingTimezone = () => {
    const { schedule, selectedSlot } = this.state;

    let timezoneMillsOffset = schedule.timezone.timezoneOffsetInMilliseconds;

    // Start At
    const localStartAt = new Date(selectedSlot.startAt);
    const localStartAtOffsetMinutes = localStartAt.getTimezoneOffset();
    const localStartAtOffsetMillis = localStartAtOffsetMinutes * 60 * 1000;
    const utcMillisStartAt = selectedSlot.startAt + localStartAtOffsetMillis;
    const targetMillisStartAt = utcMillisStartAt + timezoneMillsOffset;

    // End At
    const localEndAt = new Date(selectedSlot.startAt);
    const localEndAtOffsetMinutes = localEndAt.getTimezoneOffset();
    const localEndAtOffsetMillis = localEndAtOffsetMinutes * 60 * 1000;
    const utcMillisEndAt = selectedSlot.endAt + localEndAtOffsetMillis;
    const targetMillisEndAt = utcMillisEndAt + timezoneMillsOffset;

    return {
      id: "",
      startAt: targetMillisStartAt,
      endAt: targetMillisEndAt,
    };
  };

  onAvailableSlotSelected = (date) => {
    try {
      const { event } = this.state;
      this.setState({
        selectedSlot: {
          id: "",
          startAt: date.getTime(),
          endAt: new Date(
            date.getTime() + event.details.duration * 60000
          ).getTime(), // Add duration in milliseconds
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  moveToPrevPage = () => {
    const { currentPageIndex } = this.state;
    this.setState({
      currentPageIndex: currentPageIndex - 1,
    });
  };

  onAdditionalTextChange = (text) => {
    this.setState({
      additionalText: text,
    });
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });

      try {
        const { event, additionalText, selectedSlot } = this.state;

        const currentDate = new Date().getTimezoneOffset();
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const {
          uid,
          jobId,
          candidateId,
          fullName,
          email,
          phoneNumber,
          onSubmitted,
        } = this.props;
        const response = await axios.post(`${SUBMIT_EVENT_BOOKING}`, {
          token: uid,
          eventId: event.id,
          comment: additionalText,
          fullName: fullName,
          phoneNumber: phoneNumber,
          email: email,
          timezone: JSON.stringify({
            id: timezoneName,
            name: timezoneName,
            timezoneOffsetInMilliseconds: currentDate * 60 * 1000,
          }),
          selectedSlot: JSON.stringify(selectedSlot),
          jobId: jobId,
          candidateId: candidateId,
        });
        if (response.status === 200) {
          if (response.data["status"] === 0) {
            onSubmitted();
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.setState({
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      event,
      schedule,
      loading,
      timeslots,
      selectedDate,
      currentPageIndex,
      currentDaySlots,
      selectedSlot,
    } = this.state;

    const { fullName, email, phoneNumber } = this.props;

    if (!event) {
      return <Segment basic placeholder loading />;
    }

    return (
      <Segment basic loading={loading} style={{ padding: "0px" }}>
        <div style={{ height: "16px" }} />{" "}
        <div>
          <PageStepper pageLength={3} page={currentPageIndex} />
          <br />
        </div>
        <div>
          {currentPageIndex === 0 && (
            <EventDateSelectorSection
              event={event}
              schedule={schedule}
              timeslots={timeslots}
              selectedDate={selectedDate}
              onDateSelected={(date) => {
                this.setState({
                  loading: true,
                  selectedDate: date,
                  currentPageIndex: currentPageIndex + 1,
                  currentDaySlots: this.getDateTimeSlotsForDay(date),
                });
                this.fetchFreeBusySlots(date);
              }}
            />
          )}
          {currentPageIndex === 1 && (
            <EventSlotSelectorSection
              currentDaySlots={currentDaySlots}
              selectedDate={selectedDate}
              onSlotSelected={this.onAvailableSlotSelected}
              selectedSlot={selectedSlot}
              timeslots={timeslots}
              moveToPrevPage={this.moveToPrevPage}
              onContinueClicked={() => {
                this.setState({
                  currentPageIndex: currentPageIndex + 1,
                });
              }}
            />
          )}
          {currentPageIndex === 2 && (
            <EventDetailsSubmissionSection
              moveToPrevPage={this.moveToPrevPage}
              onAdditionalTextChange={this.onAdditionalTextChange}
              onSubmit={this.onSubmit}
              fullName={fullName}
              email={email}
              phoneNumber={phoneNumber}
            />
          )}
        </div>
      </Segment>
    );
  }
}
