import {
  ALTER_AUTH_LOADING,
  UPDATE_AUTHENTICATION,
  UPDATE_ERROR,
  UPDATE_ADDRESS_LIST,
  LOGOUT_USER,
  UPLOADING_USER_IMAGE,
  UPDATE_LOCATION,
  UPDATE_VENDOR
} from "./authConstants";

const initialState = {
  auth: null,
  profile:null,
  authLoading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALTER_AUTH_LOADING:
      return { ...state, authLoading: action.loading };
    case UPLOADING_USER_IMAGE:
      return { ...state, fileLoading: action.loading };
    case UPDATE_AUTHENTICATION:
      let newProfile = { ...(state.profile ?? {}), ...action.profile };
      return { ...state, profile: newProfile };
    case UPDATE_ERROR:
      return { ...state, error: action.error };
    case LOGOUT_USER:
      return { ...state, profile: null };
    default:
      return state;
  }
};

export default authReducer;
