export const enLocale = {
  // Candidate Form Flow
  has_recommended_you_for_a_Job: "Hi, {{firstName}} has recommended you for a Job",
  looking_for:"Looking For",
  employment_type:"Employment Type",
  address:"Address",
  would_you_like_to_be_contacted:"Would you like to be contacted for more information about the Job?",
  continue:'Continue',
  not_interested:"Not Interested",
  submit:"Submit",
  we_appreciate_interest_candidate_form_1:
    "Glad you're interested!",
  we_appreciate_interest_candidate_form_2:"Enter your details and we'll be in touch.",
  candidate_consent:"By continuing I consent sharing my contact information with '{{firstName}} {{lastName}}' on '{{wp_name}}'",
  candidate_consent_2:"My data will be shared with '{{wp_name}}'",
  candidate_consent_checkbox:'I consent that {{firstName}} gets to follow the Recuitment Process',
  could_not_complete_request:"Could not Complete Request",
  invalid_information:'Please enter Valid Information',
  already_submitted:"Already Submitted",
  candidate_submission_positive:'Hi {{firstName}}, Thank you for indicating interest on this Job you can download our app and get access to other jobs`',
  candidate_submission_negative:"There are other job opportunities available on our app that might suit you better. Let us know if you have any questions or need help",
  thanks_medalla:'Thanks,\nTeam Medalla',
  download_app:'Download the App',
  job_tips_by_downloading_app:'Get access to Job Tips by installing our mobile app.',
  job_disabled:'Sorry, this Job was Disabled by {{name_obj}}. See more opportunities on Medalla App',
  full_name:"First Name",
  email:"Email",
  phone_number:"Phone Number",
  back:"Back",
  next:"Next",
  start_quiz:"Start",
  share_quiz:"Share this Quiz with your Friends",
  load_more:"Load More",
  strongly_agree:"Strongly Agree",
  agree:"Agree",
  neutral:"Neutral",
  disagree:"Disagree",
  strongly_disagree:"Strongly Disagree",
  // Candidate Form Flow,
  rank:"Ranking",
  workplace:"Workplace",
  location:"Location",
  score:"Score",
  get_insights:"Get insight to workplaces",
  get_app:"Get App",
  candidate_consent_checkbox_c2:"No, Keep my Progress Secret",
  job_deleted:"This job is unavailable.",
  unavailable_job:"The job ad as {{role}} in {{workplaceName}} is unavailable.",
  explore_medalla:"Explore Medalla",
  select_interview_time:"Select Interview Time",
  minutes:"Minutes",
  find_attached_phone_number:"Find attached phone number",
  find_attached_web_conference_link:"Find attached web conference link",
  enter_details:"Enter Details",
  the_following_details_will_be_submitted:"The following details will be submitted for your meeting",
  details_to_prepare_for_meeting:"Please share anything that will help prepare for our meeting.",
  select_any_available_slot:"Select any available slot",
  questionaire_intro_l1:"My name is {{fullName}} and work at {{workplaceName}} to fill this position.",
  questionaire_intro_l2:"Please take a moment to answer a few questions.",
  begin:"Begin",
  question:"Question",
};
