export const JOB_STAT_ACTIONS = {
    click: "click",
    open: "open",
    report: "report",
    to_workplace: "to_workplace",
    open_interested: "open_interested",
    interested: "interested",
    open_recommend: "open_recommend",
    recommend: "recommend",
    to_user: "to_user",
    share: "share",
    link_click: "link_click",
}