import React, { Component, Fragment } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Loader,
  Progress,
  Segment,
  Table,
} from "semantic-ui-react";
import axios from "axios";
import {
  INSIGHTS_CONFIGURATION,
  RANKING_POSITION,
  WORKPLACE_AVERAGE_SCORE,
} from "../../utils/Controller/URLConstants";
import { isMobile, isSafari } from "react-device-detect";
import {
  AID_KIT,
  DOWNLOAD_QR_CODE,
  FACE_ANGRY,
  FACE_HAPPY,
  FACE_LOL,
  FACE_NEUTRAL,
  FACE_SAD,
  LOGO_ICON,
} from "../../utils/Constants/assets";
import {
  MEDALLA_TEAL,
  MEDALLA_TEAL_LOW,
} from "../../utils/Theme/ThemeConstants";
import {
  AccentButton,
  DownloadFromAppStore,
  DownloadFromPlayStore,
} from "../Custom/CustomButtons";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { TranslatedText } from "../Custom/TranslatedText";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";

export default class InsightAssessmentPage extends Component {
  prevLimit = 10;

  state = {
    configuration: null,
    sortedQuestions: [],
    score: 0,
    currentPage: 0,
    result: null,
    rankings: null,
    showResult: false,
    hasMoreEntries: false,
    loadingWorkplaces: false,
  };

  componentDidMount() {
    this.fetchConfiguration();
  }

  onResetClick = () => {
    this.setState({
      configuration: null,
      sortedQuestions: [],
      score: 0,
      currentPage: 0,
      result: null,
      rankings: null,
      showResult: false,
      hasMoreEntries: false,
      loadingWorkplaces: false,
    });
    this.fetchConfiguration();
  };

  fetchConfiguration = async () => {
    const response = await axios.get(INSIGHTS_CONFIGURATION);
    if (response.data["status"] === 0) {
      const configuration = response.data["data"];
      this.setState({
        configuration: configuration,
      });
      if (response.data["data"]) {
        this._createSortedQuestions(configuration);
      }
    }
  };

  fetchAverageScore = async () => {
    const response = await axios.get(WORKPLACE_AVERAGE_SCORE);
    if (response.data["status"] === 0) {
      const score = response.data["data"];
      return score;
    }
    return null;
  };

  fetchRankingPosition = async (score, toLoadMore = false) => {
    let QUERY = `${RANKING_POSITION}?score=${score}`;

    if (toLoadMore) {
      this.prevLimit += 10;
      QUERY += `&limit=${this.prevLimit}`;
    } else {
      this.prevLimit = 10;
    }

    if (toLoadMore) {
      this.setState({
        loadingWorkplaces: true,
      });
    }
    const response = await axios.get(QUERY);

    if (response.data["status"] === 0) {
      let workplaces = response.data["data"] ?? [];
      workplaces = workplaces.reverse();

      if (toLoadMore) {
        this.setState((prevState) => {
          return {
            result: Object.assign(prevState.result, {
              rankings: workplaces,
            }),
            loadingWorkplaces: false,
            hasMoreEntries: response.data["hasMore"] ?? false,
          };
        });
      } else {
        return [workplaces, response.data["hasMore"] ?? false];
      }
    }
    return null;
  };

  _createSortedQuestions = async (scoringConfig) => {
    let _sortedQuestions = [];

    const scoringCategories = scoringConfig["categories"];
    const questions = scoringConfig[" questions"];

    let sortedCategories = [];

    let categories = this._sortMap(scoringCategories);
    categories.forEach((entry) => {
      if (entry[1].active) {
        sortedCategories.push(entry[0]);
      }
    });

    sortedCategories.forEach((categoryKey) => {
      if (questions[categoryKey]) {
        const catQuestions = this._sortMap(questions[categoryKey]);
        catQuestions.forEach((entry) => {
          if (entry[1].active) {
            _sortedQuestions.push({
              categoryID: categoryKey,
              categoryValue: scoringCategories[categoryKey].value,
              questionID: entry[0],
              questionValue: entry[1].value,
              categoryQuestionKey: `${categoryKey}_${entry[0]}`,
              answer: null,
            });
          }
        });
      }
    });

    this.setState({
      sortedQuestions: _sortedQuestions,
    });
  };

  _sortMap = (value) => {
    let entries = Object.entries(value);

    entries.sort((entry1, entry2) => {
      if (entry1[1].index > entry2[1].index) {
        return 1;
      } else if (entry1[1].index < entry2[1].index) {
        return -1;
      } else {
        return 0;
      }
    });
    return entries;
  };

  addCategoryQuestionAnswer = (key, value) => {
    const { sortedQuestions } = this.state;
    let questionIndex = sortedQuestions.findIndex(
      (question) => question["categoryQuestionKey"] === key
    );
    if (questionIndex !== -1) {
      sortedQuestions[questionIndex] = Object.assign(
        sortedQuestions[questionIndex],
        { answer: value }
      );
      this.setState({
        sortedQuestions: sortedQuestions,
      });
    }
  };

  moveToPrevPage = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        currentPage: prevState.currentPage - 1,
      };
    });
  };

  moveToNextPage = () => {
    this.setState((prevState) => {
      const nextPage = prevState.currentPage + 1;
      const showResult = nextPage >= prevState.sortedQuestions.length + 1;
      if (showResult) {
        this.answerResult();
      }
      return {
        ...prevState,
        currentPage: nextPage,
        showResult: showResult,
      };
    });
  };

  answerResult = async () => {
    const scoreCard = await this.fetchAverageScore();
    if (scoreCard == null) return;
    if (!scoreCard["average"]) return;
    const { sortedQuestions, configuration } = this.state;
    const scoringCategories = configuration["categories"];
    let averageCalculationData = {},
      averageCalculation = {},
      categoryTitles = {};
    sortedQuestions.forEach((question) => {
      const categoryKey = question["categoryID"];
      const categoryTitle = scoringCategories[categoryKey].value;
      categoryTitles[categoryKey] = categoryTitle;
      let prevAnswers = averageCalculationData[categoryKey] ?? [];
      prevAnswers.push(question["answer"]);
      averageCalculationData[categoryKey] = prevAnswers;
    });
    let totalScore = 0;
    Object.keys(averageCalculationData).forEach((category) => {
      const answers = averageCalculationData[category];
      let sum = 0;
      answers.forEach((e) => {
        sum += e;
      });
      const score = parseFloat((sum / answers.length).toPrecision(2));
      totalScore += score;
      averageCalculation[category] = score;
    });
    const averageScore = parseFloat(
      (totalScore / Object.keys(averageCalculation).length).toPrecision(2)
    );
    averageCalculation["score"] = averageScore;
    const rankingsResult = await this.fetchRankingPosition(averageScore);

    const resultData = {};
    resultData["categories"] = categoryTitles;
    resultData["average"] = averageCalculation;
    resultData["workplaces"] = scoreCard["average"];
    resultData["rankings"] = rankingsResult[0];
    this.setState({
      score: averageScore,
      result: resultData,
      hasMoreEntries: rankingsResult[1],
    });
    setTimeout(() => {
      try {
        document.getElementById("self").scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "center",
        });
      } catch (e) {
        console.log(e);
      }
    }, 500);
  };

  render() {
    const {
      sortedQuestions,
      currentPage,
      showResult,
      result,
      hasMoreEntries,
      score,
      loadingWorkplaces,
    } = this.state;
    const questionIndex = currentPage - 1;
    // const currentQuestion =
    //   questionIndex >= 0 && sortedQuestions.length > questionIndex
    //     ? sortedQuestions[questionIndex]
    //     : null;

    return (
      <Segment
        basic
        loading={sortedQuestions.length === 0}
        style={{
          backgroundColor: isMobile || showResult ? "#FFFFFF" : MEDALLA_TEAL,
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
        inverted
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Medalla-quiz</title>
          <meta
            name="description"
            content="Gör Medalla-quizzet och se hur kollegor skattat sina arbetsplatser! 👩🏼‍⚕️👨🏾‍⚕️"
          />
        </Helmet>
        <Grid centered={!isMobile} style={{ height: "100vh" }}>
          <Grid.Column
            mobile={16}
            computer={showResult ? 16 : 10}
            tablet={16}
            verticalAlign={showResult && isMobile ? "top" : "middle"}
            style={{ paddingTop: "0px" }}
            textAlign="center"
          >
            <Segment
              padded={false}
              basic={isMobile || showResult}
              style={
                isMobile
                  ? {}
                  : {
                      height: showResult ? "100vh" : "80vh",
                      padding: "4%",
                      backgroundColor: "white",
                    }
              }
            >
              <div style={{ height: "100%" }}>
                <Grid
                  centered
                  textAlign="left"
                  style={{ height: "100%" }}
                  stretched
                >
                  <Grid.Column
                    width={16}
                    stretched
                    // verticalAlign="middle"
                    style={{ padding: "0px" }}
                  >
                    {currentPage > 0 && !showResult ? (
                      <div>
                        <Grid verticalAlign="middle" centered stackable={false}>
                          <Grid.Column width={2} textAlign="center">
                            <Button
                              icon="arrow left"
                              size="large"
                              circular
                              basic
                              onClick={this.moveToPrevPage}
                            />
                          </Grid.Column>
                          <Grid.Column width={14} verticalAlign="middle">
                            <Progress
                              size="medium"
                              percent={
                                (questionIndex / sortedQuestions.length) * 100
                              }
                              success
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                        <br />
                      </div>
                    ) : (
                      <div />
                    )}

                    <Carousel
                      selectedItem={currentPage}
                      axis="horizontal"
                      showArrows={false}
                      showIndicators={false}
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={false}
                      swipeable={false}
                      useKeyboardArrows={false}
                      emulateTouch={false}
                      infiniteLoop={false}
                    >
                      <StartPage startQuiz={this.moveToNextPage} />
                      {sortedQuestions.map((question) => {
                        return (
                          <div key={question}>
                            <AssessmentCard
                              initialValue={question["answer"]}
                              category={question["categoryValue"]}
                              question={question["questionValue"]}
                              onRateChanged={(rate, titiel) => {
                                this.addCategoryQuestionAnswer(
                                  question["categoryQuestionKey"],
                                  rate
                                );
                                this.moveToNextPage();
                              }}
                            />
                          </div>
                        );
                      })}
                      <div>
                        {isMobile ? (
                          <div style={{ position: "relative" }}>
                            <MobileAssessmentResult
                              resultData={result}
                              forComputer={!isMobile}
                              hasMoreEntries={hasMoreEntries}
                              loadingWorkplaces={loadingWorkplaces}
                              onResetClick={this.onResetClick}
                              onMoreClicked={() => {
                                this.fetchRankingPosition(score, true);
                              }}
                              nextClick={() => {
                                this.moveToNextPage();
                              }}
                            />
                            {/* <div style={{ height: "80vh" }}>
                             
                            </div> */}

                            {/* <div
                              style={{
                                position: "absolute",
                                zIndex: "9999",
                                height:"20vh",
                                bottom: 0,
                                left: 0,
                              }}
                            >
                              <Grid
                                columns={2}
                                style={{ backgroundColor: MEDALLA_TEAL }}
                              >
                                <Grid.Column>
                                  <div className="title-medium">
                                    Get Insights
                                  </div>
                                </Grid.Column>
                                <Grid.Column>
                                  <Button icon labelPosition="left">
                                    Medalla
                                    <Icon name="arrow down" />
                                  </Button>
                                </Grid.Column>
                              </Grid>
                            </div> */}
                          </div>
                        ) : (
                          <AssessmentResultCardTwo
                            resultData={result}
                            forComputer={!isMobile}
                            hasMoreEntries={hasMoreEntries}
                            loadingWorkplaces={loadingWorkplaces}
                            onMoreClicked={() => {
                              this.fetchRankingPosition(score, true);
                            }}
                            nextClick={() => {
                              this.moveToNextPage();
                            }}
                          />
                        )}
                      </div>
                      <EndPage />
                    </Carousel>
                  </Grid.Column>
                </Grid>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

function StartPage(props) {
  const END_QUOTE =
    "Arbetar du inom vården? Ta detta quiz för att jämföra din arbetsmiljö med andra arbetsplatser i Sverige.";
  function shareElement() {
    return (
      <div
        style={
          isMobile
            ? {
                position: "absolute",
                bottom: isSafari ? "15vh" : "15vh",
                left: "0px",
                width: "95vw",
                zIndex: "2",
              }
            : {}
        }
      >
        <div className="title-medium" style={{ textAlign: "center" }}>
          <TranslatedText text="share_quiz" />
        </div>
        <br />
        <Grid centered verticalAlign="bottom">
          <Grid.Column width={3} computer={1} tablet={1} textAlign="center">
            <FacebookShareButton
              // style={{ margin: "0px 1%" }}
              url={window.location.href}
              quote={END_QUOTE}
              children={<FacebookIcon size={50} round={true} />}
            />
          </Grid.Column>
          <Grid.Column width={3} computer={1} tablet={1} textAlign="center">
            <LinkedinShareButton
              // style={{ margin: "0px 1%" }}
              url={window.location.href}
              summary={END_QUOTE}
              children={<LinkedinIcon size={50} round={true} />}
            />
          </Grid.Column>
          <Grid.Column mobile={3} computer={1} tablet={1} textAlign="center">
            <EmailShareButton
              // style={{ margin: "0px 1%" }}
              url={window.location.href}
              body={END_QUOTE}
              children={<EmailIcon size={50} round={true} />}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  const { startQuiz } = props;
  return (
    <div
      style={{
        position: "relative",
        height: isMobile ? "100vh" : "80vh",
        width: isMobile ? "100vw" : "",
      }}
    >
      <Grid
        verticalAlign="middle"
        textAlign="center"
        centered
        style={{ position: "absolute", top: "20vh", width: "100%" }}
        // style={{height:"100%",width:"100%"}}
      >
        <Grid.Column verticalAlign="middle" style={{ height: "100%" }}>
          <Image src={LOGO_ICON} size={isMobile ? "tiny" : "small"} centered />
          <br />
          <br />
          <div className="title-large">UppSkatta din arbetsplats</div>
          <Header size="small">
            Gör Medalla-quizzet och se hur kollegor skattat sina arbetsplatser!
            👩🏼‍⚕️👨🏾‍⚕️
          </Header>
          <br />
          <br />
          <AccentButton
            size="large"
            onClick={startQuiz}
            text={<TranslatedText text={"start_quiz"} />}
          />
          <br />
          <br />
        </Grid.Column>
      </Grid>

      {isMobile && shareElement()}
    </div>
  );
}

function EndPage() {
  const END_QUOTE =
    "Arbetar du inom vården? Ta detta quiz för att jämföra din arbetsmiljö med andra arbetsplatser i Sverige.";
  function shareElement() {
    return (
      <div
        style={
          isMobile
            ? {
                position: "absolute",
                bottom: isMobile ? "10px" : "10px",
                left: "0px",
                width: "100%",
                zIndex: "2",
              }
            : {}
        }
      >
        <div className="title-medium" style={{ textAlign: "center" }}>
          <TranslatedText text="share_quiz" />
        </div>
        <br />
        <Grid centered verticalAlign="bottom">
          <Grid.Column width={3} computer={1} tablet={1} textAlign="center">
            <FacebookShareButton
              // style={{ margin: "0px 1%" }}
              url={window.location.href}
              quote={END_QUOTE}
              children={<FacebookIcon size={50} round={true} />}
            />
          </Grid.Column>
          <Grid.Column width={3} computer={1} tablet={1} textAlign="center">
            <LinkedinShareButton
              // style={{ margin: "0px 1%" }}
              url={window.location.href}
              summary={END_QUOTE}
              children={<LinkedinIcon size={50} round={true} />}
            />
          </Grid.Column>
          <Grid.Column mobile={3} computer={1} tablet={1} textAlign="center">
            <EmailShareButton
              // style={{ margin: "0px 1%" }}
              url={window.location.href}
              body={END_QUOTE}
              children={<EmailIcon size={50} round={true} />}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  return (
    <div style={{ height: isMobile ? "100vh" : "80vh" }}>
      <Grid stretched style={{ height: "100%" }} centered>
        <Grid.Row verticalAlign="middle" centered>
          <Grid.Column
            textAlign="center"
            verticalAlign="middle"
            computer={8}
            tablet={10}
            mobile={16}
          >
            <Image
              src={LOGO_ICON}
              size={isMobile ? "small" : "small"}
              centered
            />
            <br />
            <br />
            <div className="title-medium">
              {"Hämta Medalla för att få inblick i arbetsplatser inom vården!"}
            </div>
            <br />
            <Image src={DOWNLOAD_QR_CODE} size="medium" centered />
            {/* <Grid padded centered>
              <Grid.Row>
                <Grid.Column mobile={8} computer={5} textAlign="right">
                  <DownloadFromAppStore />
                </Grid.Column>
                <Grid.Column mobile={8} computer={5} textAlign="left">
                  <DownloadFromPlayStore />
                </Grid.Column>
              </Grid.Row>
            </Grid> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center" verticalAlign="top">
          <Grid.Column width={16}>{shareElement()}</Grid.Column>
        </Grid.Row>
      </Grid>
      {/* {isMobile ? shareElement() : <div />} */}
    </div>
  );
}

const FACES = [
  {
    icon: FACE_LOL,
    text: "strongly_agree",
  },
  {
    icon: FACE_HAPPY,
    text: "agree",
  },
  {
    icon: FACE_NEUTRAL,
    text: "neutral",
  },
  {
    icon: FACE_SAD,
    text: "disagree",
  },
  {
    icon: FACE_ANGRY,
    text: "strongly_disagree",
  },
];

function AssessmentCard(props) {
  const { category, question, initialValue, onRateChanged } = props;

  return (
    <div style={{ textAlign: "left" }}>
      <Grid stretched>
        <Grid.Column verticalAlign="top" width={16}>
          <div className="title-medium" style={{ marginBottom: "2%" }}>
            {" "}
            {category}
          </div>

          <div className="middle-text">{question}</div>
        </Grid.Column>
        <Grid.Column
          verticalAlign="middle"
          width={16}
          style={{ paddingTop: "0px" }}
        >
          <div>
            {FACES.map((face, index) => {
              const rate = 5 - index;
              const selected = initialValue === rate;
              return (
                <Button
                  onClick={() => {
                    onRateChanged(rate, face["text"]);
                  }}
                  color={selected ? "green" : null}
                  style={{
                    margin: "1em 0px",
                    backgroundColor: MEDALLA_TEAL_LOW,
                  }}
                  circular
                  key={face["text"]}
                  fluid
                  content={
                    <div>
                      <Header size="tiny" textAlign="left">
                        <Image circular src={face["icon"]} />{" "}
                        {<TranslatedText text={face["text"]} />}
                      </Header>
                    </div>
                  }
                  basic={!selected}
                />
              );
            })}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}

function MobileAssessmentResult(props) {
  const {
    resultData = {},
    // forComputer = false,
    hasMoreEntries,
    onMoreClicked,
    onResetClick,
    // loadingWorkplaces,
    // nextClick,
  } = props;

  if (!resultData) {
    return <Segment basic placeholder loading style={{ height: "70vh" }} />;
  }

  const {
    rankings,
    // categories = {},
    average = {},
    // workplaces = {},
  } = resultData;
  // const workplacesScore = workplaces["score"] ?? 0;
  // const myScore = average["score"] ?? 0;
  // let greaterThanAverage = myScore >= workplacesScore;

  return (
    <Segment
      padded={false}
      loading={!rankings}
      basic
      textAlign="center"
      style={{ padding: "0px" }}
    >
      <div>
        {rankings && (
          <Segment
            className="hide-scrollbar"
            basic
            // color="teal"
            padded={false}
            textAlign="left"
            style={{
              position: "relative",
              // overflowX: "hidden",
              // overflowY: "scroll",
              padding: "0px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                height: "10vh",
                width: "100%",
                zIndex: "9999",
                backgroundColor: MEDALLA_TEAL,
                borderRadius: "1rem",
              }}
            >
              <Segment
                style={{ position: "relative", paddingRight: "1rem" }}
                vertical
              >
                <Grid columns="equal" verticalAlign="middle" textAlign="center">
                  <Grid.Column verticalAlign="middle" width={2}>
                    <div className="title-medium" style={{ color: "#FFFFFF" }}>
                      <Button icon="redo" circular onClick={onResetClick} />
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    textAlign="left"
                    width={6}
                  >
                    <div className="large-text" style={{ color: "#FFFFFF" }}>
                      {" "}
                      <TranslatedText text={"get_insights"} />
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{ height: "10vh" }}
                    width={7}
                  >
                    <a
                      href="http://onelink.to/medalla"
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <div
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          padding: "0.5rem 0.5rem",
                          height: "100%",
                          display: "table",
                          marginRight: "1rem",
                          width: "100%",
                          fontWeight: "bold",
                          color: "#339999",
                        }}
                      >
                        <div
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            width: "100%",
                          }}
                        >
                          <TranslatedText text="get_app" />
                        </div>
                      </div>
                    </a>
                  </Grid.Column>
                </Grid>
              </Segment>
            </div>

            <div
              className="hide-scrollbar"
              id="scrollableDiv"
              style={{
                height: "85vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InfiniteScroll
                className="hide-scrollbar"
                dataLength={rankings.length} //This is important field to render the next data
                next={onMoreClicked}
                hasMore={hasMoreEntries}
                loader={
                  <div style={{ textAlign: "center", marginTop: "2rem" }}>
                    <Loader active inline />
                  </div>
                }
                endMessage={
                  <div />
                  // <p
                  //   className="title-medium"
                  //   style={{
                  //     textAlign: "center",
                  //     marginTop: "2rem",
                  //     color: MEDALLA_TEAL,
                  //   }}
                  // >
                  //   <b>You've reached the End of List</b>
                  // </p>
                }
                style={{ display: "flex", flexDirection: "column" }}
                scrollableTarget="scrollableDiv"
              >
                <div style={{ height: "12vh" }}></div>
                {rankings.map((workplace, index) => {
                  let active = workplace["ivo_id_obj"] === "self";

                  const insightsAssessment =
                    (active ? average : workplace["insightsAssessment"]) ?? {};
                  let score = insightsAssessment["score"] ?? 0;
                  let logoIsEmpty = (workplace["logoUrl"] ?? "").length === 0;
                  return (
                    <List.Item
                      id={workplace["ivo_id_obj"]}
                      className={active ? "highlight-gradient" : ""}
                      value={workplace["rank"]}
                    >
                      <Grid padded verticalAlign="middle">
                        <Grid.Column
                          width={1}
                          verticalAlign="middle"
                          textAlign="center"
                        >
                          <span
                            className="large-text"
                            style={{
                              fontWeight: active ? "bold" : "normal",
                            }}
                          >
                            {workplace["rank"]}
                          </span>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Image
                            src={logoIsEmpty ? AID_KIT : workplace["logoUrl"]}
                            bordered
                            rounded
                            style={{
                              borderRadius: "2px",
                              width: "35px",
                              height: "35px",
                              objectFit: "cover",
                              backgroundColor: "#FEFEFE",
                            }}
                          />
                        </Grid.Column>

                        <Grid.Column
                          width={7}
                          textAlign="left"
                          style={{ padding: "0px" }}
                        >
                          <div className="title-medium">
                            {" "}
                            {active ? "Min arbetsplats" : workplace["name"]}
                          </div>
                          {!active && (
                            <div
                              className="medium-text"
                              style={{ color: "#989898" }}
                            >
                              {workplace["org_name"]}
                            </div>
                          )}
                        </Grid.Column>
                        <Grid.Column
                          width={4}
                          verticalAlign="middle"
                          textAlign="left"
                        >
                          <Icon
                            name="star"
                            circular
                            inverted={active}
                            color={active ? "teal" : null}
                          />
                          <span
                            className="large-text"
                            style={{
                              fontWeight: active ? "bold" : "normal",
                              marginLeft: "2%",
                            }}
                          >
                            {parseFloat(`${score}`).toPrecision(2)}
                          </span>
                        </Grid.Column>
                      </Grid>
                    </List.Item>
                  );
                })}
              </InfiniteScroll>
            </div>
          </Segment>
        )}
      </div>
    </Segment>
  );
}

const COLORS = ["#15B3C7", "#088F8F", "#D9E024", "#E24D17"];

function AssessmentResultCardTwo(props) {
  const {
    resultData = {},
    forComputer = false,
    hasMoreEntries,
    onMoreClicked,
    loadingWorkplaces,
    nextClick,
  } = props;

  if (!resultData) {
    return <Segment placeholder loading style={{ height: "50vh" }} />;
  }

  const {
    rankings,
    categories = {},
    average = {},
    workplaces = {},
  } = resultData;
  const workplacesScore = workplaces["score"] ?? 0;
  const myScore = average["score"] ?? 0;
  let greaterThanAverage = myScore >= workplacesScore;

  return (
    <Segment padded={false} loading={!rankings} basic textAlign="center">
      <div>
        <Grid verticalAlign="middle" textAlign="left">
          <Grid.Column textAlign="left" width={14}>
            <div
              className="large-text"
              textAlign="left"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              Du har skattat din arbetsmiljö till{" "}
              {<span style={{ fontWeight: "bold" }}>{myScore}</span>} vilket är{" "}
              {
                <span
                  style={{
                    color: greaterThanAverage ? "green" : "orange",
                    fontWeight: "bold",
                  }}
                >
                  {greaterThanAverage ? "högre" : "lägre"}
                </span>
              }{" "}
              än genomsnittet i Sverige.
            </div>
          </Grid.Column>
          <Grid.Column textAlign="right" width={2}>
            <AccentButton
              size="large"
              onClick={nextClick}
              text={<TranslatedText text={"next"} />}
            />
          </Grid.Column>
        </Grid>
        <br />
        <Table celled unstackable striped size="small" attached="top">
          <Table.Header className="medium-text">
            <Table.Row>
              <Table.HeaderCell width={1} textAlign="center">
                {<TranslatedText text="rank" />}
              </Table.HeaderCell>

              <Table.HeaderCell width={3} textAlign="center">
                {<TranslatedText text="workplace" />}
              </Table.HeaderCell>
              {forComputer && (
                <Table.HeaderCell width={2} textAlign="center">
                  {<TranslatedText text="location" />}
                </Table.HeaderCell>
              )}
              <Table.HeaderCell width={2} textAlign="center">
                {<TranslatedText text="score" />}
              </Table.HeaderCell>
              {forComputer &&
                Object.keys(categories).map((category) => {
                  return (
                    <Fragment key={category}>
                      <Table.HeaderCell width={2} textAlign="center">
                        {categories[category]}
                      </Table.HeaderCell>
                    </Fragment>
                  );
                })}
            </Table.Row>
          </Table.Header>
        </Table>
        {rankings && (
          <Segment
            className="hide-scrollbar"
            basic
            padded={false}
            attached="bottom"
            style={{
              height: forComputer ? "70vh" : "45vh",
              overflowX: "hidden",
              overflowY: "scroll",
              padding: "0px",
              backgroundColor: "#EFEFEF",
            }}
          >
            <Table
              className="highlight-gradient"
              unstackable
              striped
              size="small"
              attached="bottom"
            >
              <Table.Body>
                {rankings.map((workplace, index) => {
                  let active = workplace["ivo_id_obj"] === "self";

                  let className = "medium-text";
                  const insightsAssessment =
                    (active ? average : workplace["insightsAssessment"]) ?? {};
                  let score = insightsAssessment["score"] ?? 0;
                  let logoIsEmpty = (workplace["logoUrl"] ?? "").length === 0;
                  return (
                    <Table.Row
                      id={workplace["ivo_id_obj"]}
                      style={{
                        backgroundColor: active ? "transparent" : "white",
                      }}
                      key={`${index}`}
                    >
                      <Table.Cell
                        textAlign="center"
                        width={1}
                        className={className}
                        // positive={active}
                        style={{ fontWeight: active ? "bolder" : "normal" }}
                      >
                        {workplace["rank"]}
                      </Table.Cell>
                      {/* {forComputer && (
                        <Table.Cell
                        
                          width={1}
                          className={className}
                          style={{ fontWeight: active ? "bolder" : "normal" }}
                          // positive={active}
                          textAlign="center"
                        >
                          
                        </Table.Cell>
                      )} */}
                      <Table.Cell
                        width={3}
                        className={className}
                        textAlign="left"
                        // positive={active}
                        style={{ fontWeight: active ? "bolder" : "normal" }}
                      >
                        <Grid
                          verticalAlign="middle"
                          centered
                          textAlign="center"
                        >
                          <Grid.Column width={4}>
                            <Image
                              src={logoIsEmpty ? AID_KIT : workplace["logoUrl"]}
                              bordered
                              rounded
                              style={{
                                borderRadius: "2px",
                                width: isMobile ? "35px" : "35px",
                                height: isMobile ? "35px" : "35px",
                                objectFit: "cover",
                                backgroundColor: "#FEFEFE",
                                marginRight: "2rem",
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={12}>
                            {active ? "Min arbetsplats" : workplace["name"]}
                            <br />
                            {active ? "" : workplace["org_name"]}
                          </Grid.Column>
                        </Grid>
                      </Table.Cell>
                      <Table.Cell
                        width={2}
                        className={className}
                        textAlign="center"
                        // positive={active}
                        style={{ fontWeight: active ? "bolder" : "normal" }}
                      >
                        <div style={{ width: "80%" }}>
                          {active
                            ? ""
                            : `${workplace["kommun_obj"]},${workplace["lan_obj"]}`}
                        </div>
                      </Table.Cell>
                      <Table.Cell
                        width={2}
                        className={className}
                        // positive={active}
                        textAlign="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {parseFloat(`${score}`).toPrecision(2)}
                        </div>
                      </Table.Cell>
                      {forComputer &&
                        Object.keys(categories).map((category, index) => {
                          return (
                            <Fragment key={category}>
                              <Table.Cell
                                className={className}
                                textAlign="center"
                                width={2}
                                // positive={active}
                              >
                                <div
                                  style={{
                                    width: "40px",
                                    textAlign: "center",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <CircularProgressbarWithChildren
                                    minValue={1}
                                    maxValue={5}
                                    value={insightsAssessment[category]}
                                    styles={buildStyles({
                                      pathColor: COLORS[index],
                                      trailColor: "#EFEFEF",
                                    })}
                                    strokeWidth={10}
                                  >
                                    {insightsAssessment[category]}
                                  </CircularProgressbarWithChildren>
                                </div>
                              </Table.Cell>
                            </Fragment>
                          );
                        })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {hasMoreEntries && (
              <div>
                <br />
                <Button
                  icon
                  labelPosition="right"
                  loading={loadingWorkplaces}
                  onClick={onMoreClicked}
                >
                  <TranslatedText text={"load_more"} />
                  <Icon name="arrow down" />
                </Button>

                <br />
                <br />
              </div>
            )}
          </Segment>
        )}
      </div>
    </Segment>
  );
}
