import React from 'react';
import PropTypes from 'prop-types';

const PageStepper = ({ pageLength, page }) => {
  
  const elementSpacing = 8;

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {Array.from({ length: pageLength }).map((_, index) => (
          <div key={index} style={{ flex: 1 }}>
            <div
              style={{
                marginRight: pageLength === index + 1 ? 0 : elementSpacing * 0.8,
                height: '4px',
                backgroundColor: index <= page ? '#339999' : '#ccc',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

PageStepper.propTypes = {
  pageLength: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default PageStepper;
