import React, { Component } from "react";
import {
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";

export default class LandingPage extends Component {
  render() {
    return (
      <div>
        <Segment basic style={{height:"100vh"}}>
          <Grid centered stretched>
            <Grid.Column width={16} textAlign="center" >
              <Header>Medalla Portal</Header>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}
