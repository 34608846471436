import { combineReducers } from "redux";
import testReducer from "./Test/testReducer";
import modalReducer from "./Modals/ModalReducer";
import authReducer from "./Auth/authReducer";

const rootReducer = combineReducers({
  test: testReducer,
  auth: authReducer,
  modal: modalReducer,
});

export default rootReducer;
