import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { PRODUCTION, PROJECT_MODE } from "../utils/Controller/Controller";


export const configureStore = () => {
  const middlewares = [thunk.withExtraArgument()];

  let composedEnhancer;

  if (PROJECT_MODE !== PRODUCTION) {
    composedEnhancer = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    composedEnhancer = applyMiddleware(...middlewares);
  }
  const store = createStore(rootReducer, composedEnhancer);
  return store;
};
