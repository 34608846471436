import React, { useState, useRef } from "react";
import QuestionCard from "./QuestionCard";
import { MedallaButton } from "../../Custom/CustomButtons";
import { Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const QuestionPage = ({
  question,
  initial,
  onAnswer,
  currentIndex,
  answer: initialAnswer,
  pages,
  title,
  description,
  moveToNextPage,
  moveToPreviousPage,
  submitAnswers,
}) => {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState(initialAnswer || null);
  const focusNode = useRef(null);

  const enableButton =
    (question.isRequired &&
      answer &&
      Object.keys(answer.answerData).length > 0) ||
    !question.isRequired;

  const handleAnswerChange = (newAnswer) => {
    newAnswer = {
      ...newAnswer,
      answerData: {
        ...newAnswer.answerData,
        index: currentIndex,
      },
    };

    setAnswer(newAnswer);
    onAnswer(newAnswer);
  };

  let lastPage = currentIndex === pages.length - 1;
  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MedallaButton onClick={moveToPreviousPage}>
              {t("back")}
            </MedallaButton>
            <MedallaButton
              onClick={lastPage ? submitAnswers : moveToNextPage}
              fluid
              disabled={!enableButton}
            >
              {t(lastPage ? "submit" : "next")}
            </MedallaButton>
          </div>
          <br />
        </div>
        {/* {initial ? (
          <>
            <div className="medium-text poppins-regular">{title}</div>
            <br />
            {description && (
              <>
                <p className="medium-text">{description}</p>
                <div style={{ marginTop: "16px" }} />
              </>
            )}
          </>
        ) : (
          <>
            <div className="medium-text poppins-regular">
              {t("question")} {currentIndex + 1}
            </div>
            <div style={{ marginTop: "16px" }} />
          </>
        )} */}
        <QuestionCard
          focusNode={focusNode}
          question={question}
          answer={answer}
          onChangeAnswer={handleAnswerChange}
        />
      </div>
    </div>
  );
};

export default QuestionPage;
