export function getDateForTimezoneOffset(timezoneOffsetInMills) {
  const localDate = new Date();

  const localOffset = localDate.getTimezoneOffset() * 60000;

  const offsetDifference = timezoneOffsetInMills - localOffset;

  const targetDate = new Date(localDate.getTime() + offsetDifference);

  return targetDate;
}

export function getMillisTimeFromTimezoneOffset(timezoneOffset) {
  const newDate = getDateForTimezoneOffset(timezoneOffset);
  return newDate.getTime();
}

export function getLastDayOfMonth(month, year, utc = true) {
  let nextMonth = month + 2;
  let nextYear = year;
  if (nextMonth > 12) {
    nextMonth = 1;
    nextYear++;
  }
  const firstDayNextMonth = utc
    ? new Date(Date.UTC(nextYear, nextMonth - 1, 1))
    : new Date(nextYear, nextMonth - 1, 1);
  return new Date(firstDayNextMonth.getTime() - 60000);
}

export function getMillisecondsForLastDayOfMonth(month, year, utc = true) {
  const lastDayOfMonth = getLastDayOfMonth(month, year, utc);
  return lastDayOfMonth.getTime();
}

export function getTimestampForTimezone(
  year,
  month,
  day,
  timezoneOffsetMinutes
) {
  const utcDate = new Date(Date.UTC(year, month - 1, day)); // Month is 0-based
  const timestamp = utcDate.getTime() - timezoneOffsetMinutes * 60 * 1000;
  return timestamp;
}
