import React, { Component } from "react";
import MessageModal from "./MessageModal";
import { connect } from "react-redux";

const ModalCollection = {
  MessageModal,
};

const mapState = (state) => {
  return {
    currentModal: state.modal,
  };
};

class ModalManager extends Component {
  render() {
    const { currentModal } = this.props;

    if (currentModal) {
      const SelectedModal = ModalCollection[currentModal.modalType];
      return (
        <div style={{ textAlign: "center" }}>
          <SelectedModal {...currentModal.modalProps} />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default connect(mapState)(ModalManager);
