import React from "react";
import { Button, Image } from "semantic-ui-react";
import { MEDALLA_TEAL } from "../../utils/Theme/ThemeConstants";

export const MedallaButton = (props) => {
  return (
    <div>
      <Button className="poppins-regular" {...props} inverted style={{ background: MEDALLA_TEAL }} />
    </div>
  );
};

export const AccentButton = (props) => {
  const {
    text,
    onClick,
    size,
    color,
    fluid,
    style,
    disabled,
    loading,
    icon,
    labelPosition,
    inverted = false,
  } = props;
  return (
    <Button
      icon
      inverted={inverted}
      disabled={disabled ?? false}
      loading={loading ?? false}
      fluid={fluid}
      size={size}
      labelPosition={labelPosition}
      className="title-medium poppins-regular"
      style={{
        color: "#FFFFFF",
        backgroundColor: color ?? MEDALLA_TEAL,
        outline: "none",
        borderRadius: "70px",
        padding: "1em 2em",
        ...style,
      }}
      onClick={onClick}
    >
      {icon}
      {text}
    </Button>
  );
};

export const DownloadFromAppStore = (props) => {
  const { centered = true, floated } = props;
  return (
    <div>
      <a
        href="https://apps.apple.com/us/app/medalla/id1590493412"
        target="_black"
      >
        <Image
          centered={centered}
          floated={floated}
          src={"/assets/app_store_badge.png"}
        />
      </a>
    </div>
  );
};

export const DownloadFromPlayStore = (props) => {
  const { centered = true, floated } = props;
  return (
    <div>
      <a
        href="https://play.google.com/store/apps/details?id=se.medalla.medalla&hl=en_IN&gl=US"
        target="_black"
      >
        <Image
          centered={centered}
          floated={floated}
          src={"/assets/play_store_badge.png"}
        />
      </a>
    </div>
  );
};
