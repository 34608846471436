import React, { Component } from "react";
import { Dimmer, Modal, Header, Container, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { closeModal } from "../../redux/Modals/ModalActions";
import { MedallaButton } from "../Custom/CustomButtons";

const actions = {
  closeModal,
};
const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
  };
};

class MessageModal extends Component {
  render() {
    const { modalProps } = this.props;
    const { heading, message, action, actionText } = modalProps;
    return (
      <div>
        <Dimmer />
        <Modal
          size="tiny"
          style={{ KhtmlBoxAlign: "center" }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>{heading}</Header.Content>
          </Header>
          <Modal.Content>
            <Container textAlign="center">{message}</Container>
            <br/>
            <Grid>
              <Grid.Column textAlign="center">
              {action && (
              <MedallaButton
                onClick={() => {
                  action();
                }}
              >
                {actionText ?? "Click here"}
              </MedallaButton>
            )}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <MedallaButton
              style={{ margin: "auto" }}
              onClick={() => {
                this.props.closeModal();
              }}
            >
              Okay
            </MedallaButton>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(MessageModal);
export const MODAL_CUSTOM_MESSAGE = "MessageModal";
