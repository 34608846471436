import React, { Component } from "react";
import PageStepper from "../Custom/PageStepper";
import QuestionPage from "./Components/QuestionPage";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import "react-circular-progressbar/dist/styles.css";
import { Image } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { MedallaButton } from "../Custom/CustomButtons";
import { useTranslation } from "react-i18next";

export default class AnswerQuestionaireSection extends Component {
  state = {
    answers: [],
    currentPageIndex: 0,
  };

  addQuestion = (questionId, answer) => {
    const { updateAnswers } = this.props;
    let answers = this.state.answers;
    let index = answers.findIndex((answer) => answer.questionId === questionId);
    if (index !== -1) {
      answers.splice(index, 1, answer);
    } else {
      answers.push(answer);
    }
    updateAnswers(answers);
    this.setState({});
  };

  pages = () => {
    const { currentQuestionaire } = this.props;

    const originalOrder = this.questions().map((question) => question.id);

    if (currentQuestionaire?.questionsOrder.length === originalOrder.length) {
      return currentQuestionaire.questionsOrder;
    }
    return originalOrder;
  };

  questions = () =>
    Object.values(this.props.currentQuestionaire?.questions ?? {});
  title = () => this.props.currentQuestionaire?.title ?? "";
  description = () => this.props.currentQuestionaire?.description ?? "";

  render() {
    const { currentQuestionaire, submitAnswers, user, workplaceDetails } =
      this.props;
    const { answers, currentPageIndex } = this.state;
    const rawQuestions = currentQuestionaire?.questions ?? {};
    const pages = this.pages();
    const imageSize = isMobile ? "20vw" : "100px";
    const fullNameOwner = `${user["firstName"]} ${user["lastName"]}`;
    return (
      <div>
        <div style={{ height: "16px" }} />{" "}
        {currentPageIndex > 0 && (
          <div>
            <PageStepper
              pageLength={pages.length}
              page={currentPageIndex - 1}
            />
            <br />
          </div>
        )}
        <div>
          <Carousel
            selectedItem={currentPageIndex}
            axis="horizontal"
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            autoPlay={false}
            swipeable={false}
            useKeyboardArrows={false}
            emulateTouch={false}
            infiniteLoop={false}
          >
            <div
              style={isMobile ? { position: "relative", height: "100vh" } : {}}
            >
              <Image
                src={user["profileImageUrl"]}
                centered
                circular
                style={{
                  borderRadius: imageSize,

                  width: imageSize,
                  height: imageSize,
                  objectFit: "cover",
                  backgroundColor: "#D3D3D3",
                }}
              />
              <br />
              <div className="large-text poppins-bold">{fullNameOwner}</div>
              <br />
              <QuestionaireIntroText
                workplaceName={workplaceDetails["name"]}
                ownerName={fullNameOwner}
                continue={() => {
                  if (isMobile) {
                    window.scrollTo(0, 0);
                  }
                  this.setState({
                    currentPageIndex: currentPageIndex + 1,
                  });
                }}
              />
            </div>

            {pages.map((page, index) => {
              const currentAnswer =
                answers.find((answer) => answer.questionId === page) || null;

              return (
                <QuestionPage
                  key={page}
                  currentIndex={index}
                  question={rawQuestions[page]}
                  initial={index === 0}
                  answer={currentAnswer}
                  onAnswer={(answer) => this.addQuestion(page, answer)}
                  title={currentQuestionaire["title"]}
                  description={currentQuestionaire["description"]}
                  moveToPreviousPage={() => {
                    this.setState({
                      currentPageIndex: currentPageIndex - 1,
                    });
                  }}
                  moveToNextPage={() => {
                    this.setState({
                      currentPageIndex: currentPageIndex + 1,
                    });
                  }}
                  submitAnswers={submitAnswers}
                  pages={pages}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

function QuestionaireIntroText(props) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="poppins-regular" style={{fontSize:"1.15rem"}}>
        {t("questionaire_intro_l1", {
          fullName: props.ownerName,
          workplaceName: props.workplaceName,
        })}
        <br /> {t("questionaire_intro_l2")}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: isMobile ? "32%" : "15%",
          left: "0px",
          width: "100%",
        }}
      >
        <MedallaButton size="huge" fluid={isMobile} onClick={props.continue}>
          {t("begin")}
        </MedallaButton>
      </div>
    </div>
  );
}
