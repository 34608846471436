
// Faces
export const FACE_SAD = "/assets/icons/sad.png";
export const FACE_NEUTRAL = "/assets/icons/neutral.png";
export const FACE_HAPPY = "/assets/icons/happy.png";
export const FACE_ANGRY = "/assets/icons/angry.png";
export const FACE_LOL = "/assets/icons/lol.png";

// IMAGES
export const LOGO_ICON = "/assets/logo_icon.png";
export const FULL_LOGO_ICON = "/assets/full_logo.jpg";
export const AID_KIT = "/assets/icons/aid_kit.png";
export const DOWNLOAD_QR_CODE = "/assets/qr_medalla_onelink.png";
export const JOB_UNAVAILABLE = "/assets/job_unavailable.png";

// LOTTIE
export const LOTTIE_MEDALLA = "/assets/lottie/medalla.json";