import { CLOSE_MODAL, OPEN_MODAL, UPDATE_MODAL } from "./ModalConstants";


export const openModal = (modalType, modalProps) => {
  return {
    type: OPEN_MODAL,
    payload: {
      modalType,
      modalProps,
    },
  };
};

export const closeModal = (modalType, modalProps) => {
  return {
    type: CLOSE_MODAL,
  };
};

export const updateModal = (modalType, modalProps) => {
  return {
    type: UPDATE_MODAL,
    payload: {
      modalProps,
    },
  };
};
