import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Icon } from "semantic-ui-react";

export default function EventSlotSelectorSection(props) {
  const {
    selectedDate,
    timeslots,
    moveToPrevPage,
    currentDaySlots,
    selectedSlot,
    onSlotSelected,
    onContinueClicked
  } = props;

  const {t} = useTranslation();
  
  if (!selectedDate) {
    return <br />;
  }

  


  const formattedDate = () => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };

    const formattedDate = selectedDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const showDateAsTime = (date) => {
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formattedTime;
  };
  return (
    <div>
      <Grid textAlign="left">
        <Grid.Column width={2}>
          <Button
            icon="arrow left"
            size="large"
            circular
            basic
            onClick={moveToPrevPage}
          />
        </Grid.Column>
        <Grid.Column width={14} textAlign="left">
          <div className="medium-text" style={{marginLeft:"1em"}}>{formattedDate()}</div>
          <div className="small-text"  style={{marginLeft:"1em"}}>{t("select_any_available_slot")}</div>
        </Grid.Column>
      </Grid>
      <br />
      {currentDaySlots.map((date) => {
        const selected = selectedSlot?.startAt === date.getTime();

        
        const booked = timeslots.filter(
          (slot) =>
            slot.status === "busy" &&
            date.getTime() >= slot.startTime*1000 &&
            date.getTime() < slot.endTime*1000
        ).length>0;

        let disabled = selected || booked;
        return (
          <div key={date.getTime()}>
            <Grid columns={"equal"} stackable={false}>
              <Grid.Column stretched>
                <Button
                disabled={disabled}
                secondary={disabled}
                  onClick={() => {
                    onSlotSelected(date);
                  }}
                  fluid
                  basic
                  size="large"
                >
                  {booked ? "Busy  •  " : ""}
                  {showDateAsTime(date)}
                </Button>
              </Grid.Column>
              {selected && (
                <Grid.Column stretched>
                  <Button color={"teal"} fluid onClick={()=> {
                    onContinueClicked();
                  }}>
                    {t("continue")}
                    <Icon name="arrow circle right" />
                  </Button>
                </Grid.Column>
              )}
            </Grid>
          </div>
        );
      })}
    </div>
  );
}
