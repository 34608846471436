import { Component } from "react";
import { withRouter } from "react-router";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let topReference = document.getElementById("top_ref");
      if (topReference) {
        topReference.scrollIntoView({
          inline: "start",
          behavior: "smooth",
        });
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
