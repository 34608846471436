import React from "react";
import { Icon, Segment, Table } from "semantic-ui-react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../EventScheduleComponent.css";
import { getDateForTimezoneOffset } from "../../../utils/helperFunctions";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

export default function EventDateSelectorSection(props) {
  const { event, timeslots, onDateSelected, schedule, selectedDate } = props;
  const instructions = event.details.instruction ?? "";
  const { t } = useTranslation();
  const intervals = Object.values(schedule.timeslots)
    .map((e) => Object.values(e))
    .flatMap((e) => e);

  const isSameDay = (date1, date2) => {
    if (date1 && date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    }
    return false;
  };

  const isHighlighted = (date) => {
    let now = getDateForTimezoneOffset(
      schedule.timezone.timezoneOffsetInMilliseconds
    );
    let weekday = date.getDay();
    if (weekday === 0) {
      weekday = 7;
    }

    let currentDaySlots = intervals.map((slot) => slot.date.day);

    return date.getTime() > now.getTime() && currentDaySlots.includes(weekday);
  };

  const getAttachedMedium = () => {
    switch (event.details.location["type"]) {
      case "phone":
        return t("find_attached_phone_number");
      case "conference":
        return t("find_attached_web_conference_link");

      default:
        return event.details.location["value"] ?? "";
    }
  };

  

  return (
    <div>
      <Segment
        basic
        textAlign="left"
        style={isMobile ? { padding: "0px" } : {}}
      >
        <div className="medium-text poppins-bold">{t("select_interview_time")}</div>
        <br />
        {/* <div className="medium-text">{event.details.name}</div> */}
        {instructions.length > 0 && (
          <div className="small-text">{instructions}</div>
        )}
        <Table unstackable basic="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell width={isMobile?"2":"1"} style={{ borderTop: "0px" }}>
                <Icon name="clock" />
              </Table.Cell>
              <Table.Cell width={isMobile?"14":"15"} style={{ borderTop: "0px" }}>
                {event.details.duration} {t("minutes")}
              </Table.Cell>
            </Table.Row>
            {/* <Table.Row>
              <Table.Cell style={{ borderTop: "0px" }}>
                <Icon
                  name={
                    event.details.location["type"] === "phone"
                      ? "phone"
                      : "video"
                  }
                />
              </Table.Cell>
              <Table.Cell style={{ borderTop: "0px" }}>
                {getAttachedMedium()}
              </Table.Cell>
            </Table.Row> */}
          </Table.Body>
        </Table>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            height:"70vh"
          }}
        >
          <Calendar
           className={"react-calendar-size"}
            onClickDay={(date) => {
              if (isHighlighted(date)) {
                onDateSelected(date);
              }
            }}
            tileDisabled={({ activeStartDate, date, view }) => {
              return view === "month" && !isHighlighted(date);
            }}
            tileClassName={({ date, view }) => {
              if (view === "month" && isSameDay(date, selectedDate)) {
                return "selected";
              }
              if (view === "month" && isHighlighted(date)) {
                return "highlight";
              }

              return "react-calendar__tile--disabled";
            }}
          />
        </div>
      </Segment>
    </div>
  );
}
