import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AnswerType } from "../../../utils/Constants/enums";
import { Form, Icon } from "semantic-ui-react";

const QuestionCard = ({ question, onChangeAnswer, answer: initialAnswer }) => {
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (initialAnswer) {
      setAnswer(initialAnswer);
    } else {
      setAnswer({
        questionId: question.id,
        answerType: question.data["answerType"],
        answerData: {},
      });
    }
  }, [initialAnswer, question]);

  const answerType = question.data["answerType"];

  const options = question.data["options"]
    ? Object.entries(question.data["options"])
    : [];

  const handleChange = (newAnswer) => {
    setAnswer(newAnswer);
    onChangeAnswer(newAnswer);
  };

  function capitalizeFirstLetter(field) {
    let value = field.value;
    if (value.length > 0) {
      field.value = value.charAt(0).toUpperCase() + value.slice(1);
    }
  }

  return (
    <div style={{ height: "40vh" }}>
      <div className="medium-text poppins-bold">
        {question.question}
        {question.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <div style={{ marginTop: "16px" }}>
        {answerType === AnswerType.SHORT_ANSWER && (
          <Form>
            <Form.Field>
              <textarea
                className="capital-text-field"
                type="text"
                rows="5"
                value={answer?.answerData["value"] || ""}
                onChange={(e) => {
                  capitalizeFirstLetter(e.target);
                  handleChange({
                    ...answer,
                    answerData: {
                      question: question.question,
                      value: e.target.value,
                    },
                  });
                }}
                placeholder="Enter answer here"
              />
            </Form.Field>
          </Form>
        )}
        {[AnswerType.MULTIPLE_CHOICE, AnswerType.SINGLE_CHOICE].includes(
          answerType
        ) && (
          <div>
            {options.map(([optionId, optionText], index) => {
              const selected =
                answer?.answerData["options"]?.hasOwnProperty(optionId) ||
                false;

              const icon = selected
                ? answerType === "multiple_choice"
                  ? "check circle"
                  : "selected radio"
                : answerType === "multiple_choice"
                ? "check circle outline"
                : "circle outline";

              return (
                <div
                  key={optionId}
                  style={{
                    marginBottom: index === options.length - 1 ? 0 : "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    let newOptions = { ...answer?.answerData["options"] };

                    if (answerType === "multiple_choice") {
                      if (newOptions.hasOwnProperty(optionId)) {
                        delete newOptions[optionId];
                      } else {
                        newOptions[optionId] = optionText;
                      }
                    } else {
                      newOptions = { [optionId]: optionText };
                    }

                    handleChange({
                      ...answer,
                      answerData: {
                        question: question.question,
                        options: newOptions,
                      },
                    });
                  }}
                >
                  <i
                    className="material-icons"
                    style={{
                      color: selected ? "#1976D2" : "#999",
                      fontSize: "20px",
                    }}
                  >
                    {<Icon name={icon} />}
                  </i>
                  <span style={{ marginLeft: "8px", fontSize: "16px" }}>
                    {optionText}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

QuestionCard.propTypes = {
  question: PropTypes.object.isRequired,
  onChangeAnswer: PropTypes.func.isRequired,
  focusNode: PropTypes.object,
  answer: PropTypes.object,
};

export default QuestionCard;
