import React from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { MedallaButton } from "../../Custom/CustomButtons";
import { useTranslation } from "react-i18next";

export default function EventDetailsSubmissionSection(props) {
  const {
    moveToPrevPage,
    onAdditionalTextChange,
    onSubmit,
    fullName,
    phoneNumber,
  } = props;

  const { t } = useTranslation();

  function capitalizeFirstLetter(field) {
    let value = field.value;
    if (value.length > 0) {
      field.value = value.charAt(0).toUpperCase() + value.slice(1);
    }
  }

  return (
    <div style={{ textAlign: "left" }}>
      <Grid textAlign="left">
        <Grid.Column computer={2} mobile={3}>
          <Button
            icon="arrow left"
            size="large"
            circular
            basic
            onClick={moveToPrevPage}
          />
        </Grid.Column>
        <Grid.Column
          computer={14}
          mobile={13}
          textAlign="left"
          verticalAlign="middle"
          style={{ paddingLeft: "1em" }}
        >
          <div className="large-text poppins-bold">{t("enter_details")}</div>
        </Grid.Column>
      </Grid>
      <br />
      <div className="small-text">
        {t("the_following_details_will_be_submitted")}
      </div>
      <div style={{height:"0.5em"}}/>
      <Segment style={{margin:"0px"}}>
        <Grid>
          {/* <Grid.Column width={"3"}>
            <Image
              
              circular
              size="medium"
              src="https://firebasestorage.googleapis.com/v0/b/medalla-test.appspot.com/o/configs%2Fuser-avatars%2Fnew_avatars%2Fm2.png?alt=media&token=f8256ad4-e540-4420-9037-8c1b4ce6b32c"
            />
          </Grid.Column> */}
          <Grid.Column width={"13"} verticalAlign="middle">
            <div className="medium-text poppins-bold">{fullName}</div>
            <div className="small-text poppins-regular">{phoneNumber}</div>
          </Grid.Column>
        </Grid>
      </Segment>
      <br/>
      <div className="small-text">{t("details_to_prepare_for_meeting")}</div>
      <div style={{height:"0.5em"}}/>
      <Form>
        <Form.Field>
          <textarea
            className="capital-text-field small-text"
            type="text"
            rows="5"
            onChange={(e) => {
              capitalizeFirstLetter(e.target);
              onAdditionalTextChange(e.target.value);
            }}
            placeholder="Enter additional details here"
          />
        </Form.Field>
      </Form>
      <br />
      <MedallaButton fluid size="huge" onClick={onSubmit}>
        {t("submit")}
      </MedallaButton>
    </div>
  );
}
