import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { enLocale } from "./locales/en";
import { svLocale } from "./locales/sv";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

console.log(`${process.env.PUBLIC_URL}/locales/en.json`);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: enLocale,
      },
      sv: {
        translation: svLocale,
      },
    },
    detection: {
        order: ['navigator', 'cookie', 'localStorage', 'htmlTag'],
        caches: ['localStorage', 'cookie'],
      },
  });

export default i18n;
