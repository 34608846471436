import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

export function TranslatedText(props) {
    const {text}=props;
    const {t} = useTranslation();
  return (
    <Fragment>
        {t(text)}
    </Fragment>
  )
}
