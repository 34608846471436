import { Route, Switch } from "react-router";
import "./App.css";
import LandingPage from "./components/LandingPage/LandingPage";
import CandidateApplyForm from "./components/Candidate/CandidateApplyForm";
import ModalManager from "./components/Modals/ModalManager";
import InsightAssessmentPage from "./components/Insights/InsightAssessmentPage";

function App() {
  return (
    <div>
      <ModalManager />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path={"/inblick"}  component={InsightAssessmentPage} />
        <Route path={"/candidate/apply"}  component={CandidateApplyForm} />
      </Switch>
    </div>
  );
}

export default App;
